<app-back-button />
<div class="prose m-4">
    <h1 class="text-center">DINGit acceptable use policy</h1>
    <p>DINGit permits you to use this App & website strictly for personal, non-commercial purposes only. Use of the app and website for any other purpose is prohibited. If you do not agree with the terms of this Acceptable Use Policy (“DINGit”) then you may not use this app and website. In using this app and website you agree:</p>
    <ul>
        <li>To be legally bound by the terms of this Policy;</li>
        <li>To link to our app and website only through the home page www.DINGit.com unless you have obtained our express written consent (which may be withdrawn at any time) to link to any other pages of our website;</li>
        <li>Not to use frames to present the link;</li>
        <li>Not to use the name “DINGit”, any DINGit branding or any content on the app and website for any purpose other than for your personal and non-commercial use;</li>
        <li>Not to gather, extract, reproduce and/or display on any other app, website or other online service, any material on or from the website, specifically including information relating to DINGit, whether or not by the use of spiders or other 'screen scraping' software or system used to extract data;</li>
        <li>You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on DINGit.</li>
        <li>You will not upload viruses or other malicious code.</li>
        <li>You will not solicit login information or access an account belonging to someone else.</li>
        <li>You will not bully, intimidate, or harass any user.</li>
        <li>You will not post content that: is hate speech, threatening, or pornographic; incites violence; or contains nudity or graphic or gratuitous violence.</li>
        <li>You will not use DINGit to do anything unlawful, misleading, malicious, or discriminatory.</li>
        <li>You will not use DINGit if you are under 18.</li>
        <li>You will not use DINGit if you are a convicted sex offender.</li>
        <li>You will not share your password (or in the case of developers, your secret key), let anyone else access your account, or do anything else that might jeopardize the security of your account.</li>
        <li>You will not post content or take any action on DINGit that infringes or violates someone else's rights or otherwise violates the law.</li>
        <li>If you repeatedly infringe other people's intellectual property rights, we will disable your account when appropriate.</li>
        <li>People should have the freedom to share whatever information they want, in any medium and any format, and have the right to connect online or offline with anyone - any person, organization or service - as long as they both consent to the connection.</li>
        <li>People should have the freedom to build trust and reputation through their identity and tailgate connections.</li>
    </ul>
</div>